import react from "react";
import styled from "styled-components";
import Button from "./Button";
import PText from "./PText";

const ContactBannerStyles = styled.div `
padding: 10rem 0;
.contactBanner_wrapper{
    background-color: var(--deep-dark);
    border-radius: 12px;
    padding: 5rem 0rem;
    text-align:center;
    
}
.contactBanner_heading{
font-size: 4rem;
font-family: 'helvetica';
margin-bottom: 2rem;
}
@media only screen and (max-width: 768px) {
    .contactBanner_heading {
        font-size: 1.8rem;
    }
}

`

export default function ContactBanner() {
    return(
        <ContactBannerStyles>
            <div className="container">
                <div className="contactBanner_wrapper">
                    <PText>
                        Have a project in mind?
                    </PText>
                    <h3 className="contactBanner_heading">
                        Let me help you
                    </h3>
                    <Button btnText="Contact Me" btnLink="/contact" />
                </div>
            </div>
        </ContactBannerStyles>
    )
}